import React, { Fragment } from 'react';
import { Link } from 'gatsby';

import Layout from '../App/components/layout';

import OnSendEmailVerificationButton from '../components/Session/onSendEmailVerification';
import { AuthUserContext } from '../components/Session';
import SignInForm from "../components/SignIn/index";
import { SignUpLink } from '../components/SignUp';
import { PasswordForgetLink } from '../components/PasswordForget';

import * as ROUTES from '../constants/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'

import SEO from "../App/components/seo"

const ConfirmPage = () => (
  <Fragment>
    <div className="wrapper">
        <AuthUserContext.Consumer>
          {authUser =>
            authUser ? (
              authUser.emailVerified ? (
              <>
              <Fragment>
                  <div className="wrapper">
                      <h1>Thank you for registering with us, { authUser.username }</h1>
                      <p><Link className="underlineLink" to={ROUTES.INDEX}>View Manuals <FontAwesomeIcon className="reg" icon={faArrowRight} /></Link></p>
                  </div>

              </Fragment>
              </>
              ) : (
                  <OnSendEmailVerificationButton/>
                  )
            ) : (
                <Fragment>
                    <SignInForm />
                    <div className="wrapper maxWidth"><PasswordForgetLink /></div>
                     <SignUpLink />
                </Fragment>
               )
          }
        </AuthUserContext.Consumer>
    </div>
  </Fragment>
);

export default () => (
  <Layout>
    <SEO title="" />
    <ConfirmPage />
  </Layout>
);
